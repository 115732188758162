@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500&family=Shrikhand&family=Varela+Round&display=swap");

.aboutme-main {
	display: flex;
	margin: 0 auto;
	text-align: left;
	flex-direction: column;
	font-family: "Varela Round", sans-serif;
	font-weight: 400;
	padding: 0 3rem;
	justify-content: center;
	align-items: center;
	margin-bottom: 20rem;
}

.aboutme-title,
.aboutme-title-light {
	letter-spacing: 0.5rem;
	text-align: center;
	font-size: 3.5rem;
	font-family: "Shrikhand", cursive;
	font-weight: 400;
	--bg-size: 400%;
	background: radial-gradient(
			circle,
			rgba(241, 227, 207, 1) 0%,
			rgba(238, 186, 135, 1) 50%,
			rgba(149, 84, 65, 1) 100%
		)
		0 0 / 400% 100%;
	-webkit-text-fill-color: transparent;
	color: transparent;
	background-clip: text;
	-webkit-background-clip: text;
	animation: move-bg 30s infinite linear;
}

.aboutme-title-light {
	background: radial-gradient(
			circle,
			rgba(192, 151, 111, 1) 0%,
			rgba(128, 82, 68, 1) 50%,
			rgba(47, 51, 56, 1) 100%
		)
		0 0 / 400% 100%;

	background-clip: text;
	-webkit-background-clip: text;
}

.aboutme-ppg {
	padding: 0;
	margin: 0;
	line-height: 4rem;
	margin-bottom: 2rem;
	font-size: 1.6rem;
	max-width: 550px;
}

.skills-container {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;
	width: 100%;
	max-width: 550px;
}

.skills-title {
	font-family: "Shrikhand", cursive;
	font-weight: 400;
	font-size: 2rem;
	font-weight: 400;
	margin: 0 0 1rem;
	width: 100%;
	max-width: 550px;
}

.skills__skill {
	display: flex;
	padding: 0.6rem 1.5rem;
	background: rgba(180, 106, 85, 0.2);
	border-radius: 5px;
	font-family: "Varela Round", sans-serif;
	font-weight: 400;
	font-size: 2.5rem;
}

@media screen and (max-width: 800px) {
	.aboutme-main {
		margin-top: 8rem;
	}
}

@media screen and (min-width: 950px) {
	.aboutme-main {
		max-width: 900px;
		justify-content: center;
		height: 100vh;
	}

	.aboutme-title,
	.aboutme-title-light {
		font-size: 5rem;
	}

	.aboutme-ppg {
		width: 100%;
		font-size: 2rem;
		max-width: 700px;
	}

	.skills-container {
		width: 100%;
		max-width: 700px;
	}

	.skills-title {
		max-width: 700px;
	}

	.skills__skill {
		font-size: 3rem;
		padding: 0.6rem 1.3rem;
	}
}

@media screen and (min-width: 320px) and (max-width: 920px) and (orientation: landscape) {
	.aboutme-main {
		margin-top: 10rem;
		height: auto;
	}

	.aboutme-title,
	.aboutme-title-light {
		font-size: 3.4rem;
	}

	.aboutme-ppg {
		font-size: 1.6rem;
	}

	.skills-title {
		font-size: 2rem;
	}

	.skills__skill {
		font-size: 2.5rem;
	}
}
