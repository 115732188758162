@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500&family=Shrikhand&family=Varela+Round&display=swap");

.header-main {
	width: 100%;
}

.desktop-header {
	display: none;
}

.logo {
	height: 35px;
	width: 45px;
	margin: 0;
	padding: 0;
	font-family: "Shrikhand", cursive;
	font-weight: 400;
	font-size: 2.8rem;
	text-decoration: none;
	background: none;
	border: none;
}

.logo:hover {
	cursor: pointer;
	transform: scale(1.1);
}

.mobile-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 3rem;
	position: fixed;
	height: 70px;
	box-shadow: rgba(40, 38, 38, 0.541) 0px 1px 10px 0px;
	z-index: 20;
}

.mobile-menu-inactive {
	display: flex;
	position: fixed;
	overflow-y: hidden;
	overflow-x: hidden;
	text-align: center;
	top: 0%;
	left: 100%;
	width: 100%;
	height: 100vh;
	transition: left 0.7s;
	transition-delay: 0.2s;
	z-index: 2;
}

.mobile-menu-active {
	display: flex;
	position: fixed;
	overflow-y: hidden;
	overflow-x: hidden;
	text-align: center;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100vh;
	transition: left 0.7s;
	z-index: 2;
}

.menu-items {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	list-style-type: none;
	font-family: "Shrikhand", cursive;
	font-weight: 400;
	height: 100%;
}

.menu-items-list {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 25vh;
	list-style: none;
	padding: 0;
	width: inherit;
}

.nav-link {
	background-color: transparent;
}

.header-item {
	border: none;
	text-decoration: none;
	background-color: transparent;
	font-size: 4rem;
	font-family: "Shrikhand", cursive;
	font-weight: 400;
}

.header-item:hover,
.light-dark:hover {
	transform: scale(1.1);
}

.hamburger {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: 5rem;
	background: none;
	cursor: pointer;
	border: none;
	padding: 0;
	background: radial-gradient(
			circle,
			rgba(68, 69, 70, 1) 0%,
			rgba(192, 151, 111, 1) 100%
		)
		0 0 / 400% 100%;
	background-size: 400%;
	background-repeat: repeat;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: move-bg 30s infinite linear;
	--bg-size: 400%;
}

.light-dark {
	display: flex;
	font-size: 2.8rem;
	background: none;
	border: none;
	margin: 0.5rem 0rem;
}

@media screen and (max-width: 800px) {
	.logo {
		--bg-size: 400%;
		background: radial-gradient(
				circle,
				rgba(68, 69, 70, 1) 0%,
				rgba(192, 151, 111, 1) 100%
			)
			0 0 / 400% 100%;
		background-size: 400%;
		background-repeat: repeat;
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		animation: move-bg 30s infinite linear;
	}

	@keyframes move-bg {
		to {
			background-position: var(--bg-size) 0;
		}
	}
}

@media screen and (min-width: 950px) {
	.hamburger {
		display: none;
	}

	.desktop-header {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		padding: 1rem 2.5rem;
		position: fixed;
		height: 70px;
		box-shadow: rgba(40, 38, 38, 0.541) 0px 5px 40px 0px;
		z-index: 2;
	}

	.mobile-header {
		display: none;
	}

	.desktop-nav {
		display: flex;
		gap: 1.5rem;
	}

	.menu-items {
		flex-direction: row;
		padding: 0;
		align-items: center;
	}

	.header-item {
		border-style: none;
		text-decoration: none;
		background-color: transparent;
		font-size: 3rem;
		font-family: "Shrikhand", cursive;
		font-weight: 400;
		padding: 0 15px 0;
	}

	.light-dark {
		height: 100%;
		display: flex;
		align-items: center;
		font-size: 2.8rem;
	}

	.light-dark svg {
		width: 28px;
		height: 28px;
	}
}

@media screen and (min-width: 320px) and (max-width: 920px) and (orientation: landscape) {
	.desktop-header {
		display: none;
	}

	.mobile-header {
		display: flex;
	}

	.hamburger {
		display: flex;
	}

	.menu-items {
		flex-direction: column;
		gap: 2rem;
	}

	.menu-items-list {
		width: 40%;
		height: 35vh;
	}

	.logo {
		--bg-size: 400%;
		background: radial-gradient(
				circle,
				rgba(68, 69, 70, 1) 0%,
				rgba(192, 151, 111, 1) 100%
			)
			0 0 / 400% 100%;
		background-size: 400%;
		background-repeat: repeat;
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		animation: move-bg 30s infinite linear;
	}

	.light-dark {
		margin: 0;
		height: 8vh;
	}
}
