@import url("https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@300;500&display=swap");

.footer-main {
	display: flex;
	flex-direction: column;
	padding: 2rem 2rem;
	width: 100%;
	align-items: center;
}

.footer-container {
	display: flex;
	justify-content: center;
	gap: 3rem;
	align-items: center;
	width: 100%;
}

.cv-svg,
.github-svg,
.linkedin-svg {
	display: flex;
	height: 2rem;
	padding: 0;
	margin: 0 auto;
}

.cv-svg:hover,
.github-svg:hover,
.linkedin-svg:hover {
	transform: scale(1.2);
	cursor: pointer;
}

.line {
	display: flex;
	height: 200px;
	border-left: 3px solid;
}

.sentence-container {
	display: flex;
	justify-content: center;
	width: 100%;
	text-align: center;
}

.sentence {
	display: flex;
	font-family: "Varela Round", sans-serif;
	font-weight: 400;
	font-size: 1.6rem;
	margin-top: 1.6rem;
}

@media screen and (max-width: 950px) {
	.contact-me-container {
		margin-bottom: 0px;
	}

	.line {
		display: none;
	}
}

@media screen and (min-width: 320px) and (max-width: 920px) and (orientation: landscape) {
	.line {
		display: none;
	}
}

@media screen and (min-width: 950px) {
	.footer-main {
		padding: 0;
		width: 35%;
	}

	.footer-container {
		flex-direction: column;
		margin-bottom: 1rem;
	}

	.sentence-container,
	.sentence {
		display: none;
	}
}
