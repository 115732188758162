@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500&family=Shrikhand&family=Varela+Round&display=swap");

.home-main {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
	font-family: "Varela Round", sans-serif;
	font-weight: 400;
	height: 100vh;
}

.home-title,
.home-title-light {
	letter-spacing: 0.5rem;
	text-align: center;
	font-size: 3.2rem;
	font-family: "Shrikhand", cursive;
	margin: 0;
	--bg-size: 400%;
	background: radial-gradient(
			rgba(241, 227, 207, 1) 0%,
			rgba(238, 186, 135, 1) 50%,
			rgba(149, 84, 65, 1) 100%
		)
		0 0 / 400% 100%;
	-webkit-text-fill-color: transparent;
	color: transparent;
	background-clip: text;
	-webkit-background-clip: text;
	animation: move-bg 30s infinite linear;
}

.home-title-light {
	background: radial-gradient(
			circle,
			rgba(192, 151, 111, 1) 0%,
			rgba(128, 82, 68, 1) 50%,
			rgba(47, 51, 56, 1) 100%
		)
		0 0 / 400% 100%;

	background-clip: text;
	-webkit-background-clip: text;
}

.underline {
	--bg-size: 400%;
	background: radial-gradient(
			circle,
			rgba(68, 69, 70, 1) 0%,
			rgba(192, 151, 111, 1) 100%
		)
		0 0 / 400% 100%;
	-webkit-text-fill-color: transparent;
	animation: move-bg 30s infinite linear;
	height: 5px;
	border-radius: 5px;
	width: 18%;
	margin: 1rem auto 2rem;
}

.home-ppg {
	text-align: center;
	font-size: 1.8rem;
	padding: 0 2rem 2rem;
	margin: 0;
	max-width: 550px;
}

@keyframes move-bg {
	to {
		background-position: var(--bg-size) 0;
	}
}

@media screen and (min-width: 950px) {
	.home-title,
	.home-title-light {
		font-size: 6.5rem;
	}

	.home-ppg {
		width: 70%;
		flex-wrap: wrap;
		font-size: 2.6rem;
	}

	.underline {
		width: 17%;
		margin: 1rem auto 4rem;
	}
}

@media screen and (min-width: 320px) and (max-width: 920px) and (orientation: landscape) {
	.home-title,
	.home-title-light {
		font-size: 3.2rem;
	}

	.home-ppg {
		font-size: 1.8rem;
	}
}
