@import url("https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@300;500;600;700&display=swap");

body {
	transition: background-color 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.App {
	width: 100%;
	overflow: hidden;
	overflow-y: scroll;
	overflow-x: hidden;
}

.side-menu {
	display: flex;
	flex-direction: row;
	position: fixed;
}

.header-container {
	width: calc(100% - 12px);
}

.component-container {
	display: grid;
	width: 100%;
	margin: 0px auto;
	padding: 20px 100px;
	max-width: 1000px;
	z-index: -1;
}

@media screen and (min-width: 950px) {
	.right-side-menu {
		display: flex;
		flex-direction: column;
		-webkit-box-align: center;
		align-items: center;
		justify-content: center;
		width: 35px;
		position: fixed;
		bottom: 0px;
		left: auto;
		right: 30px;
	}

	.secondary-mailto {
		margin: 20px auto;
		letter-spacing: 0.1rem;
		writing-mode: vertical-rl;
		text-decoration: none;
		font-family: "Varela Round", sans-serif;
		font-weight: 400;
		font-size: 1.6rem;
		transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	.secondary-mailto:hover {
		transform: scale(1.1);
	}

	.header-container {
		width: 100%;
	}

	.desktop-footer {
		display: flex;
		width: 90px;
		position: fixed;
		bottom: 0px;
		left: 30px;
		right: auto;
		overflow-x: hidden;
	}

	.mobile-footer {
		display: none;
	}
}

@media screen and (max-width: 950px) {
	.side-menu {
		display: none;
	}

	.right-side-menu {
		display: none;
	}

	.desktop-footer {
		display: none;
	}

	.component-container {
		margin: 0px auto;
		padding: 50px 10px 10px;
		max-width: 1000px;
	}
}

@media screen and (min-width: 320px) and (max-width: 920px) and (orientation: landscape) {
	.App {
		overflow-x: hidden;
	}

	.desktop-footer {
		display: none;
	}

	.side-menu {
		display: none;
	}

	.right-side-menu {
		display: none;
	}

	.mobile-footer {
		display: flex;
	}
}
