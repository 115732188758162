@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500&family=Shrikhand&family=Varela+Round&display=swap");

.projects-title,
.projects-title-light {
	letter-spacing: 0.5rem;
	text-align: center;
	font-size: 3.5rem;
	font-family: "Shrikhand", cursive;
	font-weight: 400;
	margin: 0;
	--bg-size: 400%;
	background: radial-gradient(
			circle,
			rgba(241, 227, 207, 1) 0%,
			rgba(238, 186, 135, 1) 50%,
			rgba(149, 84, 65, 1) 100%
		)
		0 0 / 400% 100%;
	-webkit-text-fill-color: transparent;
	color: transparent;
	background-clip: text;
	-webkit-background-clip: text;
	animation: move-bg 30s infinite linear;
}

.projects-title-light {
	background: radial-gradient(
			circle,
			rgba(192, 151, 111, 1) 0%,
			rgba(128, 82, 68, 1) 50%,
			rgba(47, 51, 56, 1) 100%
		)
		0 0 / 400% 100%;

	background-clip: text;
	-webkit-background-clip: text;
}

.projects-container {
	display: grid;
	justify-content: center;
	gap: 3rem;
	width: 88%;
	margin: 0 auto;
}

.card {
	display: flex;
	width: 100%;
	overflow: hidden;
	height: 200px;
	border-radius: 5px;
}

.card-inner-info {
	display: grid;
}

.project-details {
	display: flex;
	flex-direction: column;
	grid-area: 1/1;
	padding: 2rem;
	z-index: 1;
}

.card__image {
	display: none;
}

.mobile-card-image-container {
	grid-area: 1/1;
	border-radius: 5px;
}

.card__image__mobile {
	display: grid;
	width: 100%;
	object-fit: cover;
	filter: blur(10px);
	z-index: -1;
	height: 100%;
}

.card__title {
	font-size: 2rem;
	text-align: left;
	font-family: "Lexend Deca", sans-serif;
	font-weight: 500;
	display: flex;
	align-items: center;
	gap: 1rem;
}

.card__description {
	font-size: 1.7rem;
	padding: 1rem 0;
	font-family: "Varela Round", sans-serif;
	font-weight: 400;
}

.card__btn {
	height: 1.8rem;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
}

.card__btn:hover {
	transform: scale(1.1);
}

@media screen and (max-width: 800px) {
	.card {
		max-width: 550px;
	}

	.projects-main {
		padding-top: 9rem;
	}
}

@media screen and (min-width: 950px) {
	.projects-main {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding-top: 12rem;
	}

	.projects-title,
	.projects-title-light {
		font-size: 5rem;
	}

	.projects-container {
		width: 100%;
		margin: 0;
		gap: 7rem;
	}

	.project-details {
		padding: 0 2rem 0 0;
		height: 100%;
	}

	.card {
		width: 100%;
		height: 285px;
	}

	.card:nth-child(even) .project-details {
		padding: 0 0 0 2rem;
	}
	.card:nth-child(even) {
		flex-direction: row-reverse;
	}

	.card__title {
		font-size: 3rem;
		padding: 0;
	}

	.card__image__mobile {
		display: none;
	}

	.image-container {
		width: 100%;
		filter: contrast(1) brightness(90%);
	}

	.card__image {
		display: block;
		height: 100%;
		float: right;
		width: 100%;
		padding: 0;
		border-radius: 5px;
	}

	.card-inner-info {
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 60%;
		float: left;
	}

	.card__description {
		display: flex;
		font-size: 2rem;
	}

	.card__btn {
		height: 2.2rem;
	}
}

@media screen and (min-width: 320px) and (max-width: 920px) and (orientation: landscape) {
	.projects-main {
		margin-top: 10rem;
	}

	.projects-title,
	.projects-title-light {
		font-size: 3.5rem;
	}
}
