@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500&family=Shrikhand&family=Varela+Round&display=swap");

.contact-me-container {
	margin-top: 20rem;
	margin-bottom: 10rem;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.contact-me-title,
.contact-me-title-light {
	display: flex;
	justify-content: center;
	letter-spacing: 0.4rem;
	margin: 0;
	text-align: center;
	font-family: "Shrikhand", cursive;
	font-weight: 400;
	font-size: 3.5rem;
	--bg-size: 400%;
	background: radial-gradient(
			circle,
			rgba(241, 227, 207, 1) 0%,
			rgba(238, 186, 135, 1) 50%,
			rgba(149, 84, 65, 1) 100%
		)
		0 0 / 400% 100%;
	-webkit-text-fill-color: transparent;
	color: transparent;
	background-clip: text;
	-webkit-background-clip: text;
	animation: move-bg 30s infinite linear;
}

.contact-me-title-light {
	background: radial-gradient(
			circle,
			rgba(192, 151, 111, 1) 0%,
			rgba(128, 82, 68, 1) 50%,
			rgba(47, 51, 56, 1) 100%
		)
		0 0 / 400% 100%;
	background-clip: text;
	-webkit-background-clip: text;
}

.inner-container {
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	width: 70%;
	margin: 0 auto;
	height: 20rem;
}

.subtitle {
	display: flex;
	justify-content: center;
	text-align: center;
	font-size: 1.8rem;
	font-family: "Varela Round", sans-serif;
	font-weight: 400;
	padding: 0 2rem 0;
}

.mailto {
	background: rgba(180, 106, 85, 0.2);
	border-radius: 5px;
	width: 60%;
	height: 4.5rem;
	display: flex;
	text-decoration: none;
	font-size: 1.8rem;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	font-family: "Varela Round", sans-serif;
	font-weight: 400;
}

.mailto:hover {
	background: rgba(180, 106, 85, 0.6);
	cursor: pointer;
	transform: scale(1.04);
}

.desktop-sentence-container,
.desktop-sentence {
	display: none;
}

@media screen and (min-width: 320px) and (max-width: 920px) and (orientation: landscape) {
	.contact-me-container {
		margin-bottom: 0;
		margin-top: 10rem;
	}

	.inner-container {
		height: 15rem;
	}

	.mailto {
		width: 35%;
	}
}

@media screen and (min-width: 950px) {
	.contact-me-title,
	.contact-me-title-light {
		font-size: 5rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.subtitle {
		font-size: 2.5rem;
		padding: 0rem 2rem 0;
	}

	.mailto {
		font-size: 2.2rem;
		width: 25%;
	}

	.desktop-sentence-container {
		display: flex;
		justify-content: center;
		width: 100%;
		text-align: center;
	}

	.desktop-sentence {
		display: flex;
		font-family: "Varela Round", sans-serif;
		font-weight: 400;
		font-size: 1.8rem;
		margin-top: 3rem;
	}
}
