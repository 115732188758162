* {
	box-sizing: border-box;
	margin: 0;
	font-size: 62.5%;
}

*::-webkit-scrollbar {
	width: 0.6rem;
}

html {
	width: 100%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	min-height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen and (max-width: 800px) {
	*::-webkit-scrollbar {
		width: 0.3rem;
	}
}
